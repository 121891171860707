.App {
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 0;
  background-color: rgb(250, 250, 250);
}

.body {
  height: 100%;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
}
#about,
#work,
#experience,
#passion,
#contact{
  height: 100%;
  padding-top: 9vh;
  padding-bottom: 5vh;
}
#intro {
  background-image: url(./assets/img/decoration/polygonal-blue-background.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: "no-repeat";
  width: 100%;
  height: 80vh;
  color: white;
}
#passion {
  position: relative;
  background-image: url(./assets/img/decoration/abstract_geometrical.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: "no-repeat";
  width: 100%;
}

.footer {
  background-color: #222222;
  color: rgb(15, 13, 13);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2vh 0;
}

#intro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.479);
  z-index: 1;
}
#passion::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.479);
  z-index: 2;
}

.font_box {
  margin-top: 200px;
}

.title_container {
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;
}

.title_text_lg {
  font-size: 3rem;
  font-weight: 500;
  position: relative;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.title_text_sm {
  font-size: 1rem;
  text-decoration: underline;
  opacity: 0.7;
}

.footer_text {
  margin-bottom: 0.5vh;
  color: aliceblue;
}

.moveto{
  margin-top: 5rem;
  display: flex;
  justify-content: center;
}


.arrow {
  height: 2rem;
  z-index: 3;
}

.section_title {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  text-align: center;
  font-size: 2rem;
  color: #282c34;
}

.section_title_bl {
  text-align: center;
  font-size: 1rem;
  z-index: 2;
}
.section_container {
  position: relative;
  height: auto; 
  min-height: 65vh;
  background-color: rgb(250, 250, 250);
  color: #282c34;
  padding-top: 20px;
}


