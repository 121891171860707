.card_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 30rem));
    grid-auto-rows: auto;
    grid-gap: 1rem;
    justify-content: center;
    margin: 0 1vh;
}

.card_description {
  background-color: rgba(18, 18, 19, 0.788);
  position: absolute;
  height: 35%;
  width: 100%;
  bottom: 0;
  padding: 1em;
  color: aliceblue;
  font-weight: 600;
}

.card-img-overlay {
  padding: 0;
}
.card {
  position: relative;
}

.card a {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, -30%);
  opacity: 0; /* Le bouton sera initialement masqué */
  transition: opacity 0.1s ease-in-out, top 0.2s ease-out;
}

.card:hover a {
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  transition: top 0.2s ease-out;
  opacity: 1; /* Le bouton apparaîtra lorsque la carte est survolée */
}

.card .card-img-overlay {
  transition: background-color 0.3s ease-in-out;
}

.card:hover .card-img-overlay {
  transition: background-color 0.3s ease-out-in;
  background-color: rgba(17, 17, 17, 0.349);
  padding: 0;
}

.magniferContainer {
  z-index: 2;
}

.magniferContainer img {
  height: 5em;
}

.modal_title {
font-size: larger;
}
.modal_subtitle {
  margin: 1em 0 1em 3em; 
  list-style-type : decimal;
  font-size: medium;
  font-weight: 800;
}
.modal_text {
  margin-left: 3em;
  list-style-type : disc;
 font-weight: 400;
}

.modal_sourcecode_button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: aliceblue;
  border-radius: 10px;
  border: 2px solid rgb(39, 101, 235) ;
  background-color: rgb(7, 53, 153);
  height: 2.5em;
  width: 10em;
}
.modal_sourcecode_button:hover {
  background-color: rgb(5, 40, 116);;
}

