  ul {
    padding: 0;
    list-style-type: none;
  }
  
  .profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 2em;
  }
  
  .li_profile {
    margin: 2em 15em ;
  }
  
  .profile_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  @media only screen and (max-width: 1000px) {
    .li_profile {
      font-size: 1rem;
        margin: 2em 0 ;

    }
  }

  .profile_img_container {
    height: 15em;
    width: 15em;
    overflow: hidden; 
    border: black solid;
    border-radius: 50%;
    position: relative; 
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

  
  .profile_img {
    max-width: 100%; /* Pour s'assurer que l'image ne dépasse pas la largeur du conteneur */
    position: absolute; /* Positionnement absolu pour s'ajuster au cercle */
    top: 50%; /* Centrer verticalement */
    left: 50%; /* Centrer horizontalement */
    transform: translate(-50%, -50%);
    object-fit: cover; /* Ajuste l'image pour qu'elle couvre tout le conteneur sans déformer */
    border-radius: 50%;
  }
  
  
  
  