.section_subtitle {
  font-size: 1.5rem;
  color: #282c34;
}


.exp_container {

  margin: 5em 20vh ;
}

.techno_img {
  
  height: 8em;
  transition: height 0.5s ease; 
  padding: 1em;
}

.techno_card_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 8rem));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  justify-content: center;
  margin: 0 1vh;
}
.techno_card {
border-radius: 10px;
background-color: #282c34;
}

.techno_text {
  text-align: center;
  color:aliceblue ;
}

.card {
position: relative;
}

@media only screen and (max-width: 1000px) {
  .exp_container {
    margin: 0;
  }
}

.cvContainer {
  border-radius: 10px;
  height: 5em;
  width: 10%;
  background-color: #282c34;
}
