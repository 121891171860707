.navContainer {
  position: fixed;
  top: 0;
  z-index: 1020;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: 0 0 ;
  height: 9vh;
  width: 100%;
  transition: background 0.5s ease-in-out, height 0.5s ease-in-out;
  color: aliceblue;
  padding-right: 100px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

/* Ajoutez une classe pour le fond de la barre de navigation */
.navContainer.scrolled {
  color: aliceblue;
  background: rgb(20, 20, 20);
  height: 6vh;
  transition: background 0.5s ease-out-in, height 0.5s ease-out-in;
}
.navMenu {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.navItems {
  margin-top: 0.3em;
  font-size: 1.2em;
  text-decoration: none;
  color: aliceblue;
}
.navItems:hover {
  color: rgb(165, 160, 160);
}
.navLinkContainer {
  display: flex;
}
.navLink {
  margin-left: 10px;
  margin-right: 20px;
  align-self: center;
  text-align: end;
  text-decoration: none;
  color: aliceblue;
}
.navLink:hover {
  color: rgb(165, 160, 160);
}

.logo{
  margin: 5px 10px;
  animation: rotateHeart 10s linear infinite;
  max-height: 3em;
  /* height: 2.5em; */
}

@keyframes rotateHeart {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}
@media only screen and (max-width: 1000px) {
  .navContainer {
    display: flex;
    flex-direction: row;
    padding-right: 1.5em;
  }
.navItems {
font-size: smaller;
}

.navLinkContainer {
  display: flex;
  align-items: center;
  text-align: end;
  font-size: 1em;

  }

  .navLink {
    margin: 0 5px;
    font-size: 14px;
  }
  .logo{
    height: 2.5em;
  }

  #dropdown{
    align-self: flex-end;
  }
  
}

