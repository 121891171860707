#root {
  margin: 0;
  height: 0;
  background-color: transparent;
}
html {
  /* margin: 0;
  height: 0;
  width: 0; */
}

.ReactModal__Body--open {
  overflow: hidden;
}

